import { css } from "@emotion/core"
import lineSvg from "../../../static/assets/line.svg"
import mentor1 from "../../images/mentor-small.jpeg"
import mentor2 from "../../images/mentor1366.jpg"
import mentor3 from "../../images/mentor1440.jpg"
import mentor4 from "../../images/mentor430.jpg"
import mentorIpad from "../../images/mentor-ipad.jpg"

const styles = css`
  .e404.layout-wrapper .layout-inner {
    background: #fff;
  }
  .e404 .data-section {
    color: #000;
  }
  .aboutme.layout-wrapper .layout-inner {
    background: #fff;
  }
  .aboutme .data-section {
    color: #000;
  }
  .aboutme .hamburgercolr::before,
  .aboutme .hamburgercolr::after,
  .e404 .hamburgercolr::before,
  .e404 .hamburgercolr::after {
    background-color: #000;
  }
  .home.layout-wrapper .layout-inner {
    background: url(${mentor1});
     {
      /*background: #0e0f11;
    
    background: #0e0f11 url(${lineSvg}) center center fixed;
    background-size: contain;*/
    }
    @media (max-width: 1366px) {
      background: url(${mentorIpad}) center;
    }
    }
    @media (max-width: 1200px) {
      background: url(${mentor2}) center;
    }
    @media (max-width: 1441px) {
      background: url(${mentor3}) center;
    }
    @media (max-width: 450px) {
      background: url(${mentor4}) center;
    }
  }
  .home.layout-wrapper h1,
  .home.layout-wrapper h2 {
    color: #fff;
  }
  .skill.layout-wrapper .layout-inner {
    color: #fff;
    background: #9d316e;
    background: url(${lineSvg}) center center fixed,
      linear-gradient(45deg, #9d316e, #de2d3e);
    background-size: cover;
  }
  .experience.layout-wrapper .layout-inner {
    background: #000;
    background: url(${lineSvg}) center center fixed,
      linear-gradient(180deg, #2456a6, #a6941c);
    background-size: cover;
  }
  .home .hamburgercolr::before,
  .home .hamburgercolr::after,
  .skill .hamburgercolr::before,
  .skill .hamburgercolr::after,
  .experience .hamburgercolr::before,
  .experience .hamburgercolr::after {
    background-color: #fff;
  }
  .home .btn-contact-color,
  .experience .btn-contact-color {
    color: #fff;
  }
  .aboutme .btn-contact-color,
  .e404 .btn-contact-color {
    color: #fff;
  }
`

export default styles
